import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
    CREATE_FEE: type('[Fee] Create fee'),
    SET_FEES: type('[Fee] Set fees'),
    DISABLE_FEE: type('[Fee] Disable fee'),
    ENABLE_FEE: type('[Fee] Enable fee'),
    SET_FEE_CONTEXT: type('[Fee] Set Fee Context'),
    SET_SELECTED_FEE: type('[Fee] Set Selected Fee'),
    SET_SELECTED_USER_LINK: type('[Fee] Set Selected User Link'),
    SET_USER_LINKS: type('[Fee] Set User Links'),
    UPDATE_DASHBOARD: type('[Fee] Update Dashboard'),
    SET_HTML: type('[Fee] Set Html'),
    SET_SEARCHED_STUDENT: type('[Fee] Set Searched Student'),
    SET_STUDENTS: type('[Fee] Set Students'),
    SET_COURSES: type('[Fee] Set Courses'),
    SET_NEW_TRANSACTION: type('[Fee] Set New Transaction'),
    SET_TX_LIST: type('[Fee] Set Tx List'),
    SET_STUDENT_TX: type('[Fee] Set Student Tx'),
    SET_SELECTED_TX: type('[Fee] Set Selected Tx'),
    SET_FEE_WITH_INSTALLMENTS: type('[Fee] Set Fee Installments'),
    SET_TX_DATE: type('[Fee] Set Tx Date'),
    SET_TX_SEARCH_TERM: type('[Fee] Set Tx Search Term'),
    SET_TX_QUERY_STRING: type('[Fee] Set Tx Query String'),
    SET_FEE_TO_UPDATE: type('[Fee] Set Fee To Update')
};

export class CreateFee implements Action {
    type = ActionTypes.CREATE_FEE;
    constructor(public payload: any) {
    }
}

export class SetFees implements Action {
    type = ActionTypes.SET_FEES;
    constructor(public payload: any) {
    }
}

export class DisableFee implements Action {
    type = ActionTypes.DISABLE_FEE;
    constructor(public payload: any) {
    }
}

export class EnableFee implements Action {
    type = ActionTypes.ENABLE_FEE;
    constructor(public payload: any) {
    }
}

export class SetFeeContext implements Action {
    type = ActionTypes.SET_FEE_CONTEXT;
    constructor(public payload: any) {
    }
}
export class SetSelectedFee implements Action {
    type = ActionTypes.SET_SELECTED_FEE;
    constructor(public payload: any) {
    }
}

export class SetSelectedUserLink implements Action {
    type = ActionTypes.SET_SELECTED_USER_LINK;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class SetUserLinks implements Action {
    type = ActionTypes.SET_USER_LINKS;
    constructor(public payload: any) { }
}

export class UpdateDashboard implements Action {
    type = ActionTypes.UPDATE_DASHBOARD;
    constructor(public payload: any) { }
}

export class SetHtml implements Action {
    type = ActionTypes.SET_HTML;
    constructor(public payload: any) { }
}

export class SetSearchedStudent implements Action {
    type = ActionTypes.SET_SEARCHED_STUDENT;
    constructor(public payload: any) {
    }
}

export class SetStudents implements Action {
    type = ActionTypes.SET_STUDENTS;
    constructor(public payload: any) {
    }
}

export class SetCourses implements Action {
    type = ActionTypes.SET_COURSES;
    constructor(public payload: any) {
    }
}

export class SetNewTransaction implements Action {
    type = ActionTypes.SET_NEW_TRANSACTION;
    constructor(public payload: any) {
    }
}

export class SetTxList implements Action {
    type = ActionTypes.SET_TX_LIST;
    constructor(public payload: any) {
    }
}

export class SetStudentTx implements Action {
    type = ActionTypes.SET_STUDENT_TX;
    constructor(public payload: any) {
    }
}

export class SetSelectedTx implements Action {
    type = ActionTypes.SET_SELECTED_TX;
    constructor(public payload: any) {
    }
}

export class SetFeeWithInstallments implements Action {
    type = ActionTypes.SET_FEE_WITH_INSTALLMENTS;
    constructor(public payload: any) {
    }
}
export class SetTxDate implements Action {
    type = ActionTypes.SET_TX_DATE;
    constructor(public payload: any) {
    }
}
export class SetTxSearchTerm implements Action {
    type = ActionTypes.SET_TX_SEARCH_TERM;
    constructor(public payload: any) {
    }
}
export class SetTxQueryString implements Action {
    type = ActionTypes.SET_TX_QUERY_STRING;
    constructor(public payload: any) {
    }
}

export class SetFeeToUpdate implements Action {
    type = ActionTypes.SET_FEE_TO_UPDATE;
    constructor(public payload: any) { }
}


export type Actions
    = CreateFee | SetFees | SetSelectedUserLink | SetUserLinks | UpdateDashboard | SetHtml | SetNewTransaction | SetTxList | SetSearchedStudent | SetStudents | SetCourses | SetStudentTx | SetSelectedTx | SetFeeWithInstallments | SetTxDate | SetTxSearchTerm | SetTxQueryString | SetFeeToUpdate; 